var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container" },
          [
            _c("div", { staticClass: "top-page-header" }, [
              _c("div", { staticClass: "head-title" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("popups.integration")))]),
                _c(
                  "div",
                  { staticClass: "right-head-col" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", dark: "", large: "" },
                        on: {
                          click: function($event) {
                            _vm.dialog = true
                          }
                        }
                      },
                      [
                        _c("v-icon", [_vm._v("mdi-plus")]),
                        _vm._v(
                          _vm._s(
                            _vm.$t("popups.add") +
                              " " +
                              _vm.$t("popups.integration")
                          ) + " "
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]),
            _c(
              "v-container",
              {
                staticClass: "v-main-content stepper-template",
                attrs: { fluid: "" }
              },
              [
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.getIntegration, function(item, i) {
                      return _c(
                        "v-col",
                        { key: i, attrs: { cols: "12", sm: "5" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "pa-4 rounded-lg",
                              attrs: { elevation: "3" }
                            },
                            [
                              _c("v-card-title", [
                                _c("img", {
                                  attrs: {
                                    alt: "Integration Logo",
                                    width: "60",
                                    src: _vm.getIntegrationLogo(item.type)
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "grey--text ml-4" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "pa-0",
                                        attrs: {
                                          text: "",
                                          width: "30",
                                          "min-width": "30"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openDeleteIntegrationDialog(
                                              item.IntegrationID,
                                              item.type
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "error--text" },
                                          [_vm._v("mdi-delete-outline")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _vm._l(_vm.integrationsByTypes, function(
                    integrationGroup,
                    key1
                  ) {
                    return [
                      _c(
                        "v-row",
                        { key: key1 },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "text-left pb-0",
                              attrs: { cols: "12", sm: "12" }
                            },
                            [_c("v-divider"), _c("br")],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.getIterationArray(integrationGroup), function(
                        item,
                        index
                      ) {
                        return [
                          _c(
                            "v-row",
                            { key: index },
                            [
                              _vm._l(
                                integrationGroup.slice(
                                  index * (_vm.itemsPerRow - 1),
                                  index + _vm.itemsPerRow
                                ),
                                function(integrationItem, itemIndex) {
                                  return [
                                    _c(
                                      "v-col",
                                      {
                                        key: itemIndex,
                                        attrs: { lg: "3", md: "4", sm: "12" }
                                      },
                                      [
                                        _c(
                                          "v-sheet",
                                          {
                                            staticClass: "pa-3 rounded",
                                            attrs: {
                                              elevation: "1",
                                              color: "indigo lighten-5"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-avatar",
                                                  {
                                                    attrs: {
                                                      size: "40",
                                                      color: "none"
                                                    }
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        alt: "Integration Logo",
                                                        src: _vm.getIntegrationLogo(
                                                          integrationItem.type
                                                        )
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  { staticClass: "text-right" },
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-sm-body-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  integrationItem.name
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "error--text",
                                                            staticStyle: {
                                                              cursor: "pointer"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openDeleteIntegrationDialog(
                                                                  integrationItem.IntegrationID,
                                                                  integrationItem.type
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-delete-outline"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              )
                            ],
                            2
                          )
                        ]
                      })
                    ]
                  })
                ]
              ],
              2
            ),
            _c("DeleteIntegration", {
              attrs: { integrationDetailsForDeletion: _vm.integrationData }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "v-dialog",
              {
                attrs: { "hide-overlay": "", persistent: "", width: "300" },
                model: {
                  value: _vm.loadingState,
                  callback: function($$v) {
                    _vm.loadingState = $$v
                  },
                  expression: "loadingState"
                }
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "headline grey lighten-2" },
                      [_vm._v(" Processing... ")]
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "cust" },
                      [
                        _c("v-progress-linear", {
                          staticClass: "mb-0",
                          attrs: { indeterminate: "", color: "primary" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-center" },
          [
            _c(
              "v-dialog",
              {
                attrs: { width: "300" },
                model: {
                  value: _vm.msgModel,
                  callback: function($$v) {
                    _vm.msgModel = $$v
                  },
                  expression: "msgModel"
                }
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "headline grey lighten-2" },
                      [_vm._v(" " + _vm._s(_vm.msgTitle) + " ")]
                    ),
                    _c("v-card-text", [
                      _vm._v(" " + _vm._s(_vm.message) + " ")
                    ]),
                    _c("v-divider"),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function($event) {
                                return _vm.closeMsgModel()
                              }
                            }
                          },
                          [_vm._v(" Ok ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-right" },
          [
            _c(
              "v-dialog",
              {
                attrs: { fullscreen: "" },
                model: {
                  value: _vm.dialog,
                  callback: function($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog"
                }
              },
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-title",
                      {
                        staticClass:
                          "headline grey lighten-5 v-modal-header d-flex justify-space-between px-5 py-3 mb-5"
                      },
                      [
                        _c("h5", { staticClass: "text-h6" }, [
                          _vm._v(_vm._s(_vm.$t("integration.assPart")))
                        ]),
                        _c(
                          "v-icon",
                          {
                            on: {
                              click: function($event) {
                                _vm.dialog = false
                              }
                            }
                          },
                          [_vm._v("mdi-close")]
                        )
                      ],
                      1
                    ),
                    _c("v-card-text", [
                      _c(
                        "div",
                        { staticClass: "partner-listing listing-col-full" },
                        [
                          _c("h5", { staticClass: "text-h6" }, [
                            _vm._v("Apple")
                          ]),
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { outlined: "" }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "text-center",
                                  attrs: { "three-line": "" }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.getOAuthUrl(
                                            "APPLE_SEARCH_ADS"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-overline icon-area"
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              "max-width": "60",
                                              src: require("../assets/images/apple.png")
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "mb-1" },
                                        [_vm._v(" Apple Search Ads ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "partner-listing listing-col-full" },
                        [
                          _c("h5", { staticClass: "text-h6" }, [
                            _vm._v("Communications")
                          ]),
                          _c(
                            "v-card",
                            {
                              staticClass: "grey lighten-3",
                              attrs: { outlined: "" }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "text-center",
                                  attrs: { "three-line": "" }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.getOAuthUrl("SLACK")
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "text-overline icon-area"
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              "max-width": "60",
                                              src: require("../assets/images/slack.png")
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        { staticClass: "mb-1" },
                                        [_vm._v(" Slack ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("div", { staticClass: "integration-bottom-col" }, [
                        _c("h5", { staticClass: "text-h6" }, [
                          _vm._v("Mobile Measurement Partners")
                        ]),
                        _c("div", { staticClass: "integration-listing" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "partner-listing listing-col-full video"
                            },
                            [
                              _c("p", { staticClass: "paragraph" }, [
                                _vm._v(
                                  "Register for Free Attribution Measurement Tools"
                                )
                              ]),
                              _c(
                                "v-card",
                                {
                                  staticClass: "video-box black",
                                  attrs: { outlined: "" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "vleft-text" },
                                    [
                                      _c("h5", { staticClass: "text-h6" }, [
                                        _vm._v("Free App Analytics™")
                                      ]),
                                      _c("p", { staticClass: "paragraph" }, [
                                        _vm._v(
                                          "Fast track your mobile app growth with FREE solutions for deep linking, campaign measurement, attribution, and more."
                                        )
                                      ]),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            tile: "",
                                            elevation: "0"
                                          }
                                        },
                                        [_vm._v("Create Free Account")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "vright-col" }, [
                                    _c("iframe", {
                                      staticClass: "iframe-embed",
                                      staticStyle: {
                                        border: "4px solid rgb(239, 239, 239)",
                                        opacity: "1",
                                        visibility: "visible",
                                        width: "524.156px",
                                        height: "300.941px"
                                      },
                                      attrs: {
                                        src:
                                          "https://www.youtube.com/embed/N_VXaaZuFl0?modestbranding=1&showinfo=0&enablejsapi=1&origin=https%3A%2F%2Fwww.kochava.com",
                                        frameborder: "0",
                                        allow:
                                          "accelerometer; encrypted-media; gyroscope; picture-in-picture",
                                        allowfullscreen: "",
                                        "data-aspectratio": "0.567543623736375",
                                        "data-gtm-yt-inspected-1655224_28":
                                          "true",
                                        id: "511400814"
                                      }
                                    })
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "partner-listing listing-col-full" },
                            [
                              _c("h5", { staticClass: "text-h6" }, [
                                _vm._v("Mobile Measurement Partners")
                              ]),
                              _c("p", { staticClass: "paragraph" }, [
                                _vm._v(
                                  "Or Integrate existing Attribution Tool Providers"
                                )
                              ]),
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { outlined: "" }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "text-center",
                                      attrs: { "three-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-overline icon-area"
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "max-width": "60",
                                                  src: require("../assets/images/kochava.png")
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v(" Kochava ")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "partner-listing listing-col-full two-column"
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { outlined: "" }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "text-center",
                                      attrs: { "three-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-overline icon-area"
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "max-width": "60",
                                                  src: require("../assets/images/adjust.png")
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v(" Adjust ")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { outlined: "" }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "text-center",
                                      attrs: { "three-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-overline icon-area"
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "max-width": "60",
                                                  src: require("../assets/images/appsflyer.png")
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v(" AppsFlyer ")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { outlined: "" }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "text-center",
                                      attrs: { "three-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-overline icon-area"
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "max-width": "60",
                                                  src: require("../assets/images/tenjin.png")
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v(" Tenjin ")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                {
                                  staticClass: "grey lighten-3",
                                  attrs: { outlined: "" }
                                },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      staticClass: "text-center",
                                      attrs: { "three-line": "" }
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-overline icon-area"
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "max-width": "60",
                                                  src: require("../assets/images/singular.png")
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("v-list-item-title", [
                                            _vm._v(" Singular ")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]),
                    _c("v-divider")
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }