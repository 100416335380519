































import { DELETE_INTEGRATION } from 'src/utils/apollo-mutations';
import Vue from 'vue';
export default Vue.extend({
  name: 'DeleteIntegration',
  props: {
    integrationDetailsForDeletion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      deleting: false,
    };
  },
  watch: {
    integrationDetailsForDeletion() {
      this.dialog = true;
    },
  },
  methods: {
    async deleteIntegration() {
      this.deleting = true;
      try {
        await this.$apollo.mutate({
          mutation: DELETE_INTEGRATION,
          variables: {
            identifier: this.integrationDetailsForDeletion.id,
            type: this.integrationDetailsForDeletion.type
          },
        });
        this.deleting = false;
        this.dialog = false;
        window.location.reload();
      } catch (error) {
        this.deleting = false;
      }
    },
  },
});
