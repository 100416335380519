
export enum IntegrationTypes {
  ADJUST = 'ADJUST',
  APPLE_SEARCH_ADS = 'APPLE_SEARCH_ADS',
  APPSFLYER = 'APPSFLYER',
  KOCHAVA = 'KOCHAVA',
  SINGULAR = 'SINGULAR',
  SLACK = 'SLACK',
  TENJIN = 'TENJIN',
}

export enum CheckListFields {
  createCampaign = 'createCampaign',
  saIntegration = 'saIntegration',
  onboarding = 'onboarding',
  overviewPage = 'overviewPage',
  kaiApp = 'kaiApp',
  kaiComp = 'kaiComp',
  kaiRecom = 'kaiRecom',
  adsManager = 'adsManager',
  addMember = 'addMember',
  createRule = 'createRule',
  checkLogs = 'checkLogs',
  optimization = 'optimization',
  buyPlan = 'buyPlan',
  automationRules = 'automationRules',
  addKeys = 'addKeys',
  addnKeys = 'addnKeys',
  addCS = 'addCS',
  createAdgroup = 'createAdgroup',
  kaiKeyword = 'kaiKeyword',
  kaiTop = 'kaiTop',
  slackIntegration = 'slackIntegration',
  teamManagement = 'teamManagement',
}

