












































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { IntegrationTypes } from '@shared/enums';
import { GET_INTEGRATION } from '../utils/apollo-queries';
import { IMutationOptions } from 'src/utils/interfaces';
import DeleteIntegration from '../components/integration/DeleteIntegration.vue';
import { GET_OAUTH_URL } from 'src/utils/apollo-queries';

export default Vue.extend({
  name: 'addIntegration',
  components: { DeleteIntegration },
  // components: { DeleteIntegration, SigninAppleBtn },
  data() {
    return {
      itemsPerRow: 3,
      integrationsByTypes: {},
      loadingState: false,
      drawer: true,
      fileName: null,
      getIntegration: [],
      integrationData: null,
      mini: true,
      e1: 1,
      saving: false,
      msgModel: false,

      dialog: false,
      dialog2: false,
    };
  },
  apollo: {
    getIntegration: {
      query: GET_INTEGRATION,
      skip(): boolean {
        return false;
      },
      update(data){
        for (const integration of data.getIntegration.sort((a, b) =>
          a.type.localeCompare(b.type)
        )) {
      if (this.integrationsByTypes[integration.type]) {
        this.integrationsByTypes[integration.type].push(integration);
      } else {
        this.integrationsByTypes[integration.type] = [integration];
      }
    }

      }
    },
  },

  methods: {
    getIterationArray(array: any[]): any[] {
      return Array(Math.ceil(array.length / this.itemsPerRow));
    },
    openDeleteIntegrationDialog(id, type) {
      this.integrationData = { id: id, type: type ,now: Date.now() };
    },

    async runMutation(mutationOptions: IMutationOptions) {
      return await this.$apollo.mutate({
        mutation: mutationOptions.mutationQuery,
        variables: mutationOptions.variables,
      });
    },
    async getOAuthUrl(type: IntegrationTypes) {
      try {
        const result = await this.$apollo.query({
          query: GET_OAUTH_URL,
          variables: {
            req: { type }
          }
        });
        location.href = result.data.getOAuthState.url;
      } catch (error) {
        this.$notify.error('Unable to sign in. Please try again!');
      }
    },

    getIntegrationLogo(type: string) {
      let name: string;
      switch (type) {
        case IntegrationTypes.ADJUST: {
          name = 'adjust.png';
          break;
        }
        case IntegrationTypes.APPLE_SEARCH_ADS: {
          name = 'apple.png';
          break;
        }
        case IntegrationTypes.APPSFLYER: {
          name = 'appsflyer.png';
          break;
        }
        case IntegrationTypes.KOCHAVA: {
          name = 'kochava.png';
          break;
        }
        case IntegrationTypes.SINGULAR: {
          name = 'singular.png';
          break;
        }
        case IntegrationTypes.SLACK: {
          name = 'slack.png';
          break;
        }
        case IntegrationTypes.TENJIN: {
          name = 'tenjin.png';
          break;
        }
      }
      return require('../assets/images/' + name);
    },

    closeMsgModel() {
      this.msgModel = false;
    },

    reloadWindow() {
      window.location.reload();
    },
  },
});
